import React from 'react';
import { Stack } from '@chakra-ui/react';

import Layout from '../Layout'
import UpperSection from './UpperSection'
import TextBlock from './TextBlock'
import Text from '../../components/Text';
import Box from '../../components/Box';
import Link from '../../components/Link';
import { responsive } from '../../components/ThemeProvider/theme';
import Work, { Action } from './Work';
import SupportAndReport from '../../components/SupportAndReport';
import decoCfg from './deco.yaml'
import useLayers from '../HomePage/useLayers';
import ContactModal from '../../components/ContactModal';
// import RegisterModal from '../../components/RegisterModal';

const works = [
  {
    title: '台灣發生什麼數展覽',
    subtitle: '人人都能看懂的台灣數據展',
    imgName: '台灣發生什麼數',
    color: 'custom.lightPink',
    blocks: [
      {
        text: '短短兩天，就有超過1,200個人來看展。\n有學生、有帶孩子一起的父母、有專家、有公部門、有企業老闆、有外國人......\n都是關心台灣發展的人',
        action: {
          icon: 'more',
          label: '看更多展覽細節與介紹',
          href: 'https://www.behance.net/gallery/127557529/_',
        },
      },
      {
        text: '當初因為資源有限，展覽只舉辦了兩天，\n如果你也希望這樣的對話在你的空間中發生，\n歡迎與我們聯繫！',
        action: {
          icon: 'chat',
          label: '和我們聊聊',
          modal: ContactModal,
        },
      },
    ]
  },
  {
    title: '臺灣認知大考驗',
    subtitle: '測測你與臺灣的距離',
    imgName: 'exam',
    color: 'custom.yellow',
    blocks: [
      {
        text: '結合了對數據的愛，\n我們做了一個「認知大考驗」',
      },
      {
        text: '再次翻閱了上百份報告\n這次，我們出了一百題快問快答，',
      },
      {
        text: '十分鐘的測驗\n已經有六萬人以上完成作答\n看看自己與台灣的現況有哪些認知距離',
        action: {
          icon: 'more',
          label: '完整了解測驗',
          to: '/measure/exam',
        },
      },
    ],
  },
  {
    title: '從案發現場思考數據',
    subtitle: '與法律白話文運動一起玩',
    imgName: '法白',
    color: 'custom.yellow',
    blocks: [
      { text: '從客觀的數據出發，拆解法律問題\n看看台灣的犯罪發生什麼難題\n從滿場超過100個觀眾的臉上，\n我們看到探究精神和挑戰現況的勇氣，\n從笑聲不斷的互動中，我們覺得被希望包圍' }
    ],
  },
  {
    title: '數言臺北｜素顏臺北',
    subtitle: '2020臺北數據展',
    imgName: '數言臺北',
    color: 'custom.lightPink',
    blocks: [
      { text: '「數據還能做什麼？」\n我們用數據為城市「卸妝」，與台北市政府青委會、Qsearch 合作「素顏台北」展覽，和市民一起「數言台北」' },
      {
        text: '在剝皮寮，最跨年齡層的數據展，過去試衣間、現在雜貨店、未來紀念館...',
        action: {
          icon: 'more',
          label: '看更多展覽細節與介紹',
          href: 'https://www.behance.net/gallery/121321439/-2020',
        },
      },
    ],
  },
  {
    title: '流行趨數 Podcast',
    subtitle: '與教育部新媒體小組一起玩',
    imgName: '流行趨數',
    color: 'custom.tiffanyGreen',
    blocks: [
      {
        text: '和教育部聊了5集Podcast，用數據聊通姦除罪、休學、肥胖等流行時事',
        action: {
          icon: 'more',
          label: '收聽完整內容',
          href: 'https://open.spotify.com/episode/1j9O2KzfWD3Sf0Zc7Kr1HJ?si=awkk3yqQTymL27wREuUp3g&nd=1',
        },
      },
      {
        text: '偷偷告訴你，我們正在籌備一個全新的Podcast，面試、約會、small talk 的時候該聊什麼數？',
        action: {
          icon: 'chat',
          label: '我想收到試播輯',
          to: '/subscribe',
          // modal: p => <RegisterModal {...p} title="搶先預約「台灣發生什麼數 Podcast」上線通知" text={`台灣發生什麼數 Podcast預計在10月推出試播集！\n留下你的E-mail，島懶員就會優先通知你！`} />,
        },
      }
    ],
  },
  {
    title: '31 Books for BETTER Taiwan',
    subtitle: '與誠品書店一起玩',
    imgName: '誠品',
    color: 'custom.lightBlue',
    blocks: [
      { text: '從31個切身議題選出31種讀本\n拓展思索的角度，\n創造31種讓台灣好事發生的可能。' },
      {
        text: '想讓誠品書店為你推薦最適合的選書嗎？只要做台灣認知大考驗，就能獲得專屬選書推薦！',
        action: {
          icon: 'more',
          label: '我要獲得推薦',
          href: 'https://exam.redesigninfo.cc/'
        },
      },
    ],
  },
]

const textGroups = [
  [
    '2019年 面對隔年大選\n我們很焦慮，因為我們不夠了解台灣，卻要為他投票做決定',
    '「臺灣在我們有生以來，\n發生了哪些好數、哪些壞數？」\n我們決定花一年的時間來找答案！',
    '翻了上百份公開報告、\n厚臉皮請教了50位個領域專家，\n在過程中，我們感動了自己，\n後來，我們出了一本書、辦了一場展，\n希望這過程也能感動更多人...',
  ],
  [
    '有些人可能知道，\n這個團隊是從 Re-lab內部開始發芽\n我們原本就是一群愛實驗、\n愛研究溝通的怪人',
  ],
  [
    '後來，我們就數據上癮了，\n2020年 開始跟各種有趣的團隊合作，\n用不同的方式玩數！',
  ]
]

const interviews = [
  ['光華雜誌｜後真相時代的公民課', 'https://www.taiwan-panorama.com/Articles/Details?Guid=ec5a0a61-859d-4200-95a5-90c564c08f1b&CatId=7'],
  ['非常木蘭｜縮時台灣20年，100組一起變好的線索：《臺灣數據百閱》', 'https://www.verymulan.com/story/%E7%B8%AE%E6%99%82%E5%8F%B0%E7%81%A320%E5%B9%B4%EF%BC%8C100%E7%B5%84%E4%B8%80%E8%B5%B7%E8%AE%8A%E5%A5%BD%E7%9A%84%E7%B7%9A%E7%B4%A2%EF%BC%9A%E3%80%8A%E8%87%BA%E7%81%A3%E6%95%B8%E6%93%9A%E7%99%BE%E9%96%B1%E3%80%8B-14876.html'],
  ['報橘｜《臺灣認知大考驗》10 分鐘考驗你對台灣認識有多深，你有自信拿滿分嗎？', 'https://buzzorange.com/citiorange/2020/12/10/exam-for-taiwan/'],
]

const AboutPage = ({ data: { allFile: { nodes: images } } }) => {
  // const { width } = useWindowSize();
  const decos = useLayers(images, true)(decoCfg, 0)
  const worksDeco = [
    [decos[4]],
    [decos[5]],
    null,
    [decos[6], decos[7]],
  ]
  return (
    <Layout bg="custom.bgGray" title="認數我們">
      <UpperSection images={images} />
      <Stack spacing={responsive('3rem', '4.5rem')}>
        <TextBlock texts={textGroups[0]} decos={[decos[0]]} />
        <Work {...works[0]} side={1} decos={[decos[1]]} py={responsive(0, '8rem')} images={images} />
        <TextBlock texts={textGroups[1]} decos={[decos[2]]} />
        <Work {...works[1]} side={0} py={responsive(0, '8rem')} images={images} />
        <TextBlock texts={textGroups[2]} decos={[decos[3]]} />
        {works.slice(2).map((w, i) => <Work {...w} side={(i + 1) % 2} key={i} decos={worksDeco[i]} images={images} />)}
        <TextBlock>
          <Box py="3em">
            <Text.P>
              2021年 我們決定把這座島開放<br />
              這個網站，集結了我們最新的計劃和實驗<br />
              以後，你可以隨時上來，和我們一起玩數
            </Text.P>
          </Box>
          <Text.Bold lineHeight="1.75" fontSize={responsive('1.125em', '1.5em')}>最後，如果你對我們的努力有共鳴<br />可以逛逛記念品店，支持我們走更遠</Text.Bold>
          <Stack>
            <Text.S>台灣發生什麼數是一個非營利的計畫，</Text.S>
            <Text.S>我們每半年都會說明前一期的贊助做了哪些數，同時也會公開接下來我們想做的數，</Text.S>
            <Text.S>如果喜歡的話歡迎逛逛紀念品，支持我們挖掘更多島上有趣好玩的數！</Text.S>
          </Stack>
        </TextBlock>
      </Stack>
      <SupportAndReport />
      <Stack spacing={responsive('3rem', '4.5rem')} pb={responsive('2.5rem', '8rem')}>
        <TextBlock decos={[decos.slice(8)]}>
          <Text.Title>看看別人怎麼介紹我們</Text.Title>
          <Stack spacing="1em">
            {interviews.map(([text, href], i) => (
              <Link
                key={i}
                fontWeight="700"
                href={href}
                fontSize={responsive('1em', '1.25em')}
                textDecoration="underline"
              >{text}</Link>
            ))}
          </Stack>
          <Stack spacing={responsive(0, '0.5em')}>
            <Text.S>如果你也想和我們「跨領域」對話...</Text.S>
            <Box>
              <Action icon="chat" label="和我們聊聊" modal={ContactModal} />
            </Box>
          </Stack>
        </TextBlock>
      </Stack>
    </Layout>
  );
}

export default AboutPage;
