import { graphql } from 'gatsby'
import Page from '../containers/AboutPage'

export default Page

export const query = graphql`
query AboutQuery {
  allFile(filter: {sourceInstanceName: {eq: "about_images"}}) {
    nodes {
      name
      publicURL
      relativePath
      childImageSharp {
        gatsbyImageData
      }
    }
  }
}
`
