import React, { createElement, useMemo } from 'react'
import { Button, Stack, useDisclosure } from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { BiMessageRoundedDetail } from 'react-icons/bi'
import { getSrc } from 'gatsby-plugin-image'

import Frame from './Frame'
import Box from '../../components/Box'
import Text from '../../components/Text'
import Link from '../../components/Link'
import Container from '../../components/Container'
import { Media, responsive } from '../../components/ThemeProvider/theme'

const icons = {
  more: FiSearch,
  chat: BiMessageRoundedDetail,
}

export const Action = ({ icon, label, modal, href, to }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        fontSize={responsive("0.875em", '1.125em')}
        leftIcon={createElement(icons[icon], { size: '1.25em' })}
        colorScheme="whiteAlpha"
        variant="ghost"
        color="black"
        px="0"
        py="0"
        height="auto"
        onClick={modal && onOpen}
        as={(href || to) && Link}
        href={href}
        to={to}
        textDecoration="underline"
      >
        {label}
      </Button>
      {modal && createElement(modal, { isOpen, onClose })}
    </>
  )
}

const Block = ({ text, action }) => (
  <Stack>
    <Text.S>{text}</Text.S>
    {action && (
      <Box>
        <Action {...action} />
      </Box>
    )}
  </Stack>
)

const Work = ({
  title,
  subtitle,
  blocks,
  color,
  side,
  decos,
  images,
  imgName,
  ...props
}) => {
  const slideImages = useMemo(() => images.filter(i => i.relativePath.startsWith(imgName)).map(i => getSrc(i)), [imgName, images])
  return (
    <Box position="relative" {...props}>
      <Media greaterThan="mobile">{decos}</Media>
      <Container as={Stack} direction={responsive('column', side ? 'row-reverse' : 'row')} alignItems={responsive('auto', 'center')}>
        <Stack flex="2">
          <Box>
            <Text.Title>{title}</Text.Title>
            <Text.P as="h3">{subtitle}</Text.P>
          </Box>
          <Stack spacing="1.75rem">
            {blocks.map((b, i) => <Block {...b} key={i} />)}
          </Stack>
        </Stack>
        <Box flex="3" px={responsive(0, '5%')} pt={responsive('1rem', 0)}>
          <Frame color={color} images={slideImages} side={side} />
        </Box>
      </Container>
    </Box>
  )
}

export default Work
