import { Box, Stack } from '@chakra-ui/layout'
import React from 'react'

import Text from '../../components/Text'
import Container from '../../components/Container'
import { Media, responsive } from '../../components/ThemeProvider/theme'

const TextBlock = ({ children, texts, decos, ...props }) => {
  return (
    <Box position="relative" {...props}>
      <Media greaterThan="mobile">{decos}</Media>
      <Container>
        <Stack
          spacing={responsive('2rem', '3rem')}
          width={responsive('100%', '35%')}
          mx="auto"
        >
          {children || texts.map((t, i) => (
            <Text.P key={i}>{t}</Text.P>
          ))}
        </Stack>
      </Container>
    </Box>
  )
}

export default TextBlock
