import React, { useMemo, useState } from 'react'
import { nanoid } from 'nanoid'
import { motion, AnimatePresence } from "framer-motion"
import { useHarmonicIntervalFn } from 'react-use'

import SVG from '../../components/SVG'


const Frame = ({ side, images, ...props }) => {
  const id = useMemo(() => nanoid(), [])
  const [activeIndex, setActiveIndex] = useState(0)
  const src = images[activeIndex]
  useHarmonicIntervalFn(() => setActiveIndex(i => i + 1 === images.length ? 0 : i + 1), 2500)

  return (
    <SVG viewBox="0 0 595 618" transform={!side && `scale(-1, 1)`} {...props}>
      <defs>
        <clipPath id={id}>
          <path d="m548.34 500.73-442.8 51.2a65.06 65.06 0 0 1-14-16.52 69.16 69.16 0 0 1-6.43-15.93c-3.56-14.2-29-202.78-63.13-451.9l433.39-44.8c26.91 236.22 51.3 413.86 59.88 432.82a152.53 152.53 0 0 0 13.26 23 139.18 139.18 0 0 0 19.83 22.13Z" fill="none" />
        </clipPath>
      </defs>
      <path d="M140.66 615.84h-.72l-.56-.47c-16.19-13.78-33.93-22.75-54.82-31.67-6.6-2.82-13.53-5.64-20.82-8.6-15.72-6.4-33.12-13.48-52.58-22.73l-4.82-2.3 5.24-1c9.35-1.88 35.94-4.39 70.86-7.17s78.5-5.84 121.83-8.78c21.1-1.44 42.14-2.84 62.14-4.18 62.19-4.16 114.22-7.64 126-9.11l.81-.11.61.55c12.1 11 35 24.38 61.94 38.37S513.53 587 541.45 600l-.9 3.39c-84.88-7.17-237.82.83-399.89 12.45Z" fill="#fff" stroke="#000" strokeWidth="3.56" />
      <path d="m141.85 605.17-.73.05-.55-.47C124.38 591 106.64 582 85.75 573.08c-6.6-2.82-13.53-5.64-20.82-8.6-15.72-6.4-33.13-13.48-52.58-22.73l-4.83-2.29 5.24-1.06c9.36-1.88 35.94-4.39 70.87-7.17s78.5-5.83 121.83-8.78c21.09-1.43 42.14-2.84 62.13-4.18 62.2-4.16 114.23-7.63 126-9.11l.81-.11.61.55C407.11 520.55 430 534 457 548s57.76 28.4 85.69 41.37l-.9 3.39c-84.94-7.21-237.88.79-399.94 12.41Z" fill="#fff" stroke="#000" strokeWidth="3.56" />
      <path d="M141.85 588.57h-.73l-.55-.47c-16.19-13.78-33.93-22.75-54.82-31.67-6.6-2.82-13.53-5.64-20.82-8.6-15.72-6.4-33.13-13.48-52.58-22.73l-4.83-2.29 5.24-1.06c9.36-1.88 35.94-4.39 70.87-7.17s78.5-5.83 121.83-8.78c21.09-1.43 42.14-2.84 62.13-4.18 62.2-4.16 114.23-7.63 126-9.11l.81-.11.61.55c12.1 10.95 35 24.38 61.94 38.37s57.76 28.4 85.69 41.37l-.9 3.39c-84.89-7.08-237.83.92-399.89 12.49Z" fill="#fff" stroke="#000" strokeWidth="3.56" />
      <path d="M548.34,500.73l-442.8,51.2a65.06,65.06,0,0,1-14-16.52,69.16,69.16,0,0,1-6.43-15.93c-3.56-14.2-29-202.78-63.13-451.9l433.39-44.8C482.28,259,506.67,436.64,515.25,455.6a152.53,152.53,0,0,0,13.26,23A139.18,139.18,0,0,0,548.34,500.73Z" fill="#fff" stroke="#000" strokeWidth="3.37"/>
      <g clip-path={`url(#${id})`}>
        <AnimatePresence>
          <motion.image
            key={src}
            width="600"
            height="600"
            transform={side ? `rotate(-5.46 688.315 -91.192) scale(.82)` : 'matrix(-0.82, 0.08, 0.08, 0.82, 506.7, 16.75)'}
            href={src}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          />
        </AnimatePresence>
      </g>
      <path d="m548.34 500.73-442.8 51.2a65.06 65.06 0 0 1-14-16.52 69.16 69.16 0 0 1-6.43-15.93c-3.56-14.2-29-202.78-63.13-451.9l433.39-44.8c26.91 236.22 51.3 413.86 59.88 432.82a152.53 152.53 0 0 0 13.26 23 139.18 139.18 0 0 0 19.83 22.13Z" fill="none" stroke="#000" strokeWidth="3.37" />
      <path d="M252.6 58.16a1.66 1.66 0 0 1-.12.31l-2.81 6.22-6.7 1 .25-9.14a2 2 0 0 1 0-.49 35 35 0 0 0 9.35 2.15Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.17" />
      <path d="M252.91 56.61a4.68 4.68 0 0 1-.33 1.56 34.93 34.93 0 0 1-9.35-2.17 5 5 0 0 1 3.06-4 4.65 4.65 0 0 1 2.3-.33 4.86 4.86 0 0 1 4.3 5Z" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.95" />
      <path d="M270.32 48.23a7 7 0 0 1-.11 2.46c-1.21 5.31-8.67 8.31-17.6 7.5a35.3 35.3 0 0 1-9.35-2.19c-8.39-3.19-13.79-9.1-12.58-14.42a7 7 0 0 1 1-2.27c.67 5 6.5 10.1 14.64 12.64.86.27 1.74.51 2.64.74s1.8.38 2.68.51c8.42 1.26 15.89-.77 18.68-4.97Z" fill="currentColor" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.17" />
      <path d="M271.3 46a7 7 0 0 1-1 2.27c-3.08 4.65-11.89 6.64-21.38 4.45s-16.54-7.83-17.27-13.36a6.63 6.63 0 0 1 .11-2.47 4.27 4.27 0 0 1 .13-.48c1.08-3.48 4.93-5.88 10.1-6.76 2.33-4.38 3.82-10.26 4.56-13.66 1.67 2.47 5.19 4.66 9.54 5.67s8.48.56 11.06-.92c-.83 3.37-2.07 9.32-1.89 14.28 4.26 3.05 6.68 6.89 6.12 10.49a2.66 2.66 0 0 1-.08.49Z" fill="currentColor" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.17" />
      <path d="M247 13.55c-.08.43-.24 1.28-.48 2.41Z" fill="currentColor" />
      <path d="M247 13.55c-.08.43-.24 1.28-.48 2.41" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.95" />
      <path d="M267.16 20.7c.27-1.11.5-1.95.62-2.37Z" fill="currentColor" />
      <path d="M267.16 20.7c.27-1.11.5-1.95.62-2.37" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.95" />
      <path d="M269.77 17.51a5.28 5.28 0 0 1-1 1.87 5 5 0 0 1-1.65 1.32c-2.59 1.48-6.7 1.92-11.06.92s-7.87-3.2-9.54-5.66a5.26 5.26 0 0 1-.91-1.91 5.17 5.17 0 0 1-.05-2.11 4.59 4.59 0 0 1 .6-1.38c1.89-2.85 7.27-4.06 13.08-2.72s10.09 4.79 10.57 8.16a4 4 0 0 1-.04 1.51Z" fill="currentColor" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.17" />
      <path d="M256.78 18.77c-6.67-1.54-11.37-5.87-10.49-9.68S253.28 3.45 260 5s11.37 5.87 10.49 9.68-7.03 5.63-13.71 4.09Z" fill="currentColor" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.17" />
      <path d="M265.11 35.36c-.84 3.65-6.7 5.38-13.08 3.93S241.14 33.65 242 30" fill="currentColor" />
      <path d="M265.11 35.36c-.84 3.65-6.7 5.38-13.08 3.93S241.14 33.65 242 30" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.17" />
      <path d="M258.91 34.9c-.8-3 .36-6 1.53-8.69a1.56 1.56 0 0 1 .85-.73 1.54 1.54 0 0 1 1.94.8 1.55 1.55 0 0 1 .09 1.12c-.26.59-.52 1.18-.75 1.82-.11.31-.23.59-.33.93-.05.16-.1.31-.14.47l-.06.2c0-.17 0-.16 0 0a12.84 12.84 0 0 0-.27 2v.68a7.92 7.92 0 0 0 .19 1 1.55 1.55 0 0 1-.3 1.16 1.54 1.54 0 0 1-2.18.3 1.61 1.61 0 0 1-.62-1ZM237.7 40.19c.28.32.57.62.87.91-.26-.26 0 0 .08.07l.18.17.47.42a21 21 0 0 0 2.06 1.56c.35.23.7.45 1.07.66.06 0 .33.18 0 0l.27.15.56.29a20.42 20.42 0 0 0 2.36 1 1.62 1.62 0 0 1 .84.85 1.56 1.56 0 0 1 0 1.2 1.6 1.6 0 0 1-.85.83 1.58 1.58 0 0 1-1.19 0 23 23 0 0 1-9.25-6.22 1.53 1.53 0 0 1-.33-1.15 1.56 1.56 0 0 1 .57-1.05 1.64 1.64 0 0 1 1.15-.32 1.55 1.55 0 0 1 1 .56Z" fill="#fff" />
    </SVG>
  )
}

export default Frame
