import React, { useCallback } from 'react'
import { AspectRatio } from '@chakra-ui/layout'

import useLayers from '../HomePage/useLayers'

import webImages from './web.yaml'
import mobileImages from './mobile.yaml'
import { Media } from '../../components/ThemeProvider/theme'
import Container from '../../components/Container'
import Text from '../../components/Text'

const texts = [
  '哦哦哦！\n沒想到真的有人\n會想認識我們！',
  '我們總是在數據、報告堆裡打滾，\n從凌亂的資料中找到有趣的故事！',
  '「所以你們到底在做什麼？」\n看來這次島懶員也完全沒有介紹啊......',
  '認識一個團隊，\n不要聽他們「說了什麼」，\n應該看他們「做了什麼」，\n所以，直接來看看吧！'
]

const UpperSection = ({ images }) => {
  const getLayers = useLayers(images)
  const getRatioBox = useCallback((cfg) => {
    let [imgs, textLayers, title] = cfg.images.reduce((res, img) => {
      if (img.name === 'about') {
        res[2] = img
        return res
      }
      res[+img.name.startsWith('text')].push(img)
      return res
    }, [[], []])
    textLayers = textLayers.sort((a, b) => a.name > b.name ? 1 : -1).map((t, i) => ({
      ...t,
      width: 'auto',
      children: <Text.P>{texts[i]}</Text.P>,
    }))
    return (
      <AspectRatio ratio={cfg.W / cfg.H}>
        <div>
          {getLayers({ ...cfg, images: [{ ...title, as: 'h1', children: <span style={{ opacity: 0 }}>認數我們</span> }] })}
          {getLayers({ ...cfg, images: imgs })}
          {getLayers({ ...cfg, images: textLayers })}
        </div>
      </AspectRatio>
    )
  }, [getLayers])
  return (
    <>
      <Media at="mobile">
        {getRatioBox(mobileImages)}
      </Media>
      <Media greaterThan="mobile">
        <Container position="relative">
          {getRatioBox(webImages)}
        </Container>
      </Media>
    </>
  )
}

export default UpperSection
