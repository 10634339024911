import { Box } from '@chakra-ui/layout'
import React, { createElement, useCallback } from 'react'
import BackgroundImage from '../../components/BackgroundImage'

const useLayers = (images, vh) => {
  return useCallback((cfg, headerOffset = 64, onLoad, wrapper) => {
    const canvasWidth = cfg.W
    const canvasHeight = cfg.H
    return cfg.images.map((layer, i) => {
      const src = images.find(i => i.name === layer.name)?.publicURL
      const ele = (
        <Box
          position="absolute"
          width={`${(layer.W || layer.width) / canvasWidth * 100}%`}
          top={`${((layer.Y || layer.top) - (vh ? 0 : headerOffset)) / canvasHeight * 100}${vh ? 'vh' : '%'}`}
          left={`${(layer.X || layer.left) / canvasWidth * 100}%`}
          transform={layer.Rotation && `rotate(${layer.Rotation}deg)`}
          key={i}
        >
          {src ? (
            <BackgroundImage
              as={layer.as}
              ratio={(layer.W || layer.width) / (layer.H || layer.height)}
              src={src}
              onLoad={() => {
                if (onLoad) onLoad(i)
              }}
            >
              {layer.children}
            </BackgroundImage>
          ) : layer.children}
        </Box>
      )
      return wrapper ? createElement(wrapper, { key: i }, ele) : ele
    })
  }, [images, vh])
}

export default useLayers
